import React, { Component } from 'react';
import Paragraph from '../../components/content/paragraphs/Paragraph/Paragraph';
import { Image } from 'react-bootstrap';
import TeamSelector from '../../components/content/team/TeamSelector/TeamSelector';
import Meta from '../../components/atoms/Meta/Meta';
import { Redirect } from 'react-router-dom';

import './PersonDetailPage.css';
import Include from '../../drupal/Include';

export default class PersonDetailPage extends Component {

    state = {
        position: null
    };

    componentDidMount = async () => {
        const positionId = this.props.entity.get('field_primary_position');

        if(positionId) {
            const position = await this.props.api.getEntity('paragraph', 'position', positionId);

            this.setState({
                position: position.get('field_job_title')
            });
        }

    }

    render = () => {
        if(this.props.entity.get('field_disable_detail_page')) {
            return (
                <Redirect to="/404" />
            );
        }

        let content = [];
        let imageUrl = null;
        let body1 = null;
        let body2 = null;
        let body3 = null;
        let hasImageClass = 'no-image';
        let jobTitle = null;

        let image = this.props.entity.get('field_image');
        if(image instanceof Include) {
            console.log(image.get('field_media_image'));
            imageUrl = image.get('field_media_image').get('links').large_square.href;
            
            image = (
                <div className="image"><Image src={imageUrl} alt={image.get('field_media_image').get('alt')} /></div>
            );

            hasImageClass = 'has-image';
        }

        if(this.state.position) {
            jobTitle = (
                <div className="job-title">
                    {this.state.position}
                </div>
            )
        }

        const fieldContent = this.props.entity.get('field_content');

        for(let i = 0; i < fieldContent.length; i++) {
            if(i === 0) {
                body1 = (
                    <div className="body-1">
                        <Paragraph content={fieldContent[i]} drupal={this.props.drupal} />
                    </div>
                );
            } else if(i === 1) {
                body2 = (
                    <div className="body-2">
                        <Paragraph content={fieldContent[i]} drupal={this.props.drupal} />
                    </div>
                );
            // } else if(i === 2) {
            //     body3 = (
            //         <div className="body-3">
            //             <Paragraph content={fieldContent[i]} drupal={this.props.drupal} />
            //         </div>
            //     );
            } else {
                content.push(
                    <div className="body">
                        <Paragraph content={fieldContent[i]} drupal={this.props.drupal} />
                    </div>
                );
            }
        }

        const meta = <Meta data={this.props.entity.get('meta')} />

        return (
            <div className="content-wrapper container page-content">
                {meta}

                <TeamSelector menu={this.props.menu} align="left" location={this.props.location} />

                <div className={`person-detail-page ${hasImageClass}`}>
                    
                    <h1 className="title">{this.props.entity.get('title')}</h1>
                    
                    {jobTitle}

                    {image}

                    {body1}

                    {body2}

                    {body3}

                    {content}
                </div>
            </div>
        );

    }
}