import React, { Component } from 'react';
import PropertyWidgetPickerItem from '../PropertyWidgetPickerItem/PropertyWidgetPickerItem';

import './PropertyWidgetPicker.css';

export default class PropertyWidgetPicker extends Component {
    
    render() {

        return (
            <div className="property-widget-picker">
                <PropertyWidgetPickerItem active={this.props.activeWidget === 'grid' ? true : false} value="grid" label="Grid View" onSelect={this.props.onSelect} />
                <PropertyWidgetPickerItem active={this.props.activeWidget === 'list' ? true : false} value="list" label="List View" onSelect={this.props.onSelect} hideForMobile={true} />
                <PropertyWidgetPickerItem active={this.props.activeWidget === 'map' ? true : false} value="map" label="Map View" onSelect={this.props.onSelect} />
            </div>
        );

    }

}