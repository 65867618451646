import React, { Component } from 'react';

import './PropertyWidgetPickerItem.css';

export default class PropertyWidgetPickerItem extends Component {
    
    render() {

        let activeClass = 'inactive';
        let hideForMobileClass = null;

        if(this.props.active) {
            activeClass = 'highlighted';
        }

        if(this.props.hideForMobile) {
            hideForMobileClass = 'd-none d-lg-inline-block';
        }

        return (
            <div className={`property-widget-picker-item button light ${activeClass} ${hideForMobileClass} ${this.props.value}`} onClick={(e) => { this.props.onSelect(this, e) }}>{this.props.label}</div>
        );

    }

}