import React, { Component } from 'react';
import { Image } from 'react-bootstrap';

import './BuildingMini.css';

export default class BuildingMini extends Component {

    render() {
        return (
            <div className="building-mini">
                <div className="title">
                    {this.props.building.get('field_name')}
                </div>
            </div>
        );
    }

}