import React, { Component } from 'react';
import { Image } from 'react-bootstrap';
import Button from '../../../../atoms/Button/Button';

import './MarketSliderItem.css';

export default class MarketSliderItem extends Component {

    render() {
        return (
            <div className="market-slider-item">
                <Image src={this.props.imageUrl} alt={this.props.imageAlt} />
                <div className="overlay"></div>
                <div className="content">
                    <h3>{this.props.title}</h3>
                    <div className="active-indicator"></div>
                    <Button buttonClasses="ghost" url={this.props.linkUrl} text="View Properties" />
                </div>
            </div>
        );
    }

}