import React, { Component } from 'react';
import PropertyGridItem from '../../PropertyGridItem/PropertyGridItem';
import ContentGrid from '../../../../layout/ContentGrid/ContentGrid';

export default class PropertyGridWidget extends Component {

    state = {
        items: []
    };

    componentDidMount = () => {
        let items = [];

        if(this.props.properties.hasOwnProperty('data')) {
            for(const item of this.props.properties.data) {
                items.push(<PropertyGridItem entity={item} linkFieldOverride={this.props.linkFieldOverride} isCustomerLink={this.props.isCustomerGrid} theme={this.props.theme} />);
            }
        

            this.setState({
                items: items
            });
        }
    }
    
    render() {

        return (
            <div className="property-grid-widget">
                <ContentGrid small={12} medium={4} large={3}>{this.state.items}</ContentGrid>
            </div>
        );

    }

}