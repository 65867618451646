import React, { Component } from 'react';
import Text from '../../../atoms/Text/Text';
import { Timeline, TimelineItem }  from 'vertical-timeline-component-for-react';

import './TimelineParagraph.css';

export default class TimelineParagraph extends Component {

  render() {
    let title = null;
    if(this.props.title) {
        title = (
            <h2>{this.props.title}</h2>
        );
    }

    let subtitle = null;
    if(this.props.subtitle) {
        subtitle = (
            <h4>{this.props.subtitle}</h4>
        );
    }

    let items = [];
    for(let item of this.props.items) {

        let text = null;
        if(item.get('field_text')) {
            text = <Text text={item.get('field_text').get('field_body').processed} />;
        }

        let title = null;
        if(item.get('field_title')) {
            title = (
                <h4>{item.get('field_title')}</h4>
            );
        }
    
        let subtitle = null;
        if(item.get('field_subtitle')) {
            subtitle = (
                <h5>{item.get('field_subtitle')}</h5>
            );
        }
    
        items.push(
            <TimelineItem key={item.get('id')} dateText={item.get('field_date_text')}>
                {title}
                {subtitle}
                {text}
            </TimelineItem>
        );
    }

    return (
        <div className="paragraph timeline-paragraph page-section padded">
            {title}
            {subtitle}
            <Timeline>
                {items}
            </Timeline>
        </div>
    );
  }

}