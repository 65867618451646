import React, { Component } from 'react';
import BuildingFact from '../BuildingFact/BuildingFact';
import { Button } from 'react-bootstrap';
import ContentGrid from '../../../../layout/ContentGrid/ContentGrid';

import './BuildingFactGrid.css';

export default class BuildingFactGrid extends Component {

    render() {
        let buttons = null;
        let facts = [];

        if(this.props.currentBuilding !== null) {

            for(let fact of this.props.currentBuilding.get('field_customer_information')) {
                facts.push(<BuildingFact isGridItem={true} label={fact.get('field_name')} value={fact.get('field_value')} />);
            }

            if(this.props.currentBuilding.get('field_customer_quick_links').length > 0) {
                let links = [];

                for(let link of this.props.currentBuilding.get('field_customer_quick_links')) {
                    links.push(<Button target="_blank" href={this.getLinkUrl(link.uri)}>{link.title}</Button>);
                }

                buttons = (
                    <div className="buttons">
                        {links}
                    </div>
                );
            }
        }

        return (
            <div className="building-fact-grid">

                <ContentGrid centerIncompleteRow={true} small={12} medium={2} large={3}>
                    {facts}
                </ContentGrid>                  

                {buttons}
            </div>
        );
    }

    getLinkUrl = (uri) => {
        const url = uri.replace("internal:", "").replace("entity:", "");

        return url;
    }

}