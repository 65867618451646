import React, { Component } from 'react';
import MarketSlider from '../../market/MarketSlider/MarketSlider/MarketSlider';
import Media from 'react-media';
import RecentArticlesList from '../../article/RecentArticlesList/RecentArticlesList';
import MarketGrid from '../../market/MarketGrid/MarketGrid';

export default class FrontPageContent extends Component {

    render() {
        return (
            <div className="page-section padded">
                <Media query="(max-width: 1399px)" render={() => (
                    <MarketGrid api={this.props.api} />
                )} />

                <Media query="(min-width: 1400px)" render={() => (
                    <MarketSlider api={this.props.api} />
                )} />


                <RecentArticlesList limit={2} fancyTitle={true} api={this.props.api} />
            </div>
        );
    }

}
