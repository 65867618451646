import React, { Component } from 'react';

import './LocalVideo.css';

export default class LocalVideo extends Component {

    render() {
        if(this.props.autoPlay) {
            return (
                <video autoPlay muted={true} controls playsinline="true" controlsList="nodownload" onCanPlayThrough={this.play}>
                    <source src={this.props.url} type="video/mp4" />
                </video>
            );
        }

        return (
            <video controls playsinline="true" controlsList="nodownload">
                <source src={this.props.url} type="video/mp4" />
            </video>
        )
    }

    play = (e) => {
        e.currentTarget.play();
    }

}
