import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import './Modal.css';

export default class Modal extends Component {
    
    render() {
        return (
            <div className="modal-overlay">
                <div className="modal-overlay-header">
                    <div onClick={this.props.handleClose} className="modal-close-trigger">
                        <FontAwesomeIcon icon={faTimes} />
                    </div>
                </div>

                <div className="modal-overlay-content">
                    { this.props.children }
                </div>

            </div>
        );
    }
}