import React, { Component } from 'react';

import './SelectorItem.css';

export default class SelectorItem extends Component {

    render() {
        return (
            <div className="selector-item" onClick={(e) => { this.props.handleSelect(this, e) }}>{this.props.children}</div>
        );
    }

}