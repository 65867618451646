import React, { Component } from 'react';

export default class MoreListItem extends Component {

    render() {
        return (
            <div className="more-list-item">{this.props.children}</div>
        );
    }

}