import React, { Component } from 'react';

export default class PhoneLink extends Component {

  render() {
    return (
      <a href={this.buildTelephoneHref(this.props.number)}>{this.props.number}</a>
    );
  }

  buildTelephoneHref(string) {
    return 'tel:' + string.replace(/\D/g,'');
  }

}