import React, { Component } from 'react';

import './Text.css';

export default class Text extends Component {

  render() {

    const html = {
      __html: this.props.text
    };

    return (
      <div className="text paragraph editor-body page-section padded" dangerouslySetInnerHTML={html} />
    );
  }

}