import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faNewspaper, faBuilding, faUser, faProjectDiagram, faUsers } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

import './SearchResult.css';

export default class SearchResult extends Component {

    render() {

        let icon = faFileAlt;

        switch(this.props.type) {
            case 'node--article':
                icon = faNewspaper;
                break;
            case 'node--property':
                icon = faBuilding;
                break;
            case 'node--person':
                icon = faUser;
                break;
            case 'node--market':
                icon = faProjectDiagram;
                break;
            case 'node--team':
                icon = faUsers;
                break;
            default:
                icon = faFileAlt;
        }

        return (
            <div className="search-result row">

                <div className="col-1 icon">
                    <FontAwesomeIcon icon={icon} />
                </div>

                <div className="col-11 content">
                    <Link to={this.props.linkUrl}><h2 className="title">{this.props.title}</h2></Link>
                    <div className="teaser">{this.props.teaser}</div>
                </div>
            </div>
        );
    }

}
