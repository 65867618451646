import React, { Component } from 'react';
import PropertyCard from '../../PropertyCard/PropertyCard';
import PropertyListItem from '../../PropertyListItem/PropertyListItem';

export default class PropertyListWidget extends Component {

    state = {
        currentItemId: null,
        items: []
    };

    componentDidMount = () => {
        let items = [];

        if(this.props.properties.hasOwnProperty('data')) {
            for(const item of this.props.properties.data) {
                items.push(<PropertyListItem key={item.id} entity={item} onSelect={this.handleSelect} />);
            }
        

            this.setState({
                currentItemId: this.getFirstProperty().get('id'),
                items: items
            });
        }
    }
    
    render() {

        const currentProperty = this.getCurrentProperty();

        const selectorItems = this.state.items;

        let currentPropertyId = null;

        if(currentProperty) {
            currentPropertyId = currentProperty.id;
        }

        return (
            <div className="property-list-widget">
                <PropertyCard key={currentPropertyId} entity={currentProperty} selectorItems={selectorItems} handleSelect={this.handleSelect} />
            </div>
        );

    }

    getFirstProperty = () => {
        if(this.props.properties.data.length > 0) {
            return this.props.properties.data[0];
        }

        return null;
    }

    getCurrentProperty = () => {
        let currentProperty = null;

        if(this.state.currentItemId != null) {
            currentProperty = this.state.items.find(obj => {
                return obj.props.entity.get('id') === this.state.currentItemId;
            });

            return currentProperty.props.entity;
        }

        return currentProperty;
    }

    handleSelect = (selectorItem) => {
        this.setState({
            currentItemId: selectorItem.props.children.props.entity.get('id')
        });
    }

}