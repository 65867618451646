import React, { Component } from 'react';
import Entity from '../../drupal/Entity';
import Paragraph from '../../components/content/paragraphs/Paragraph/Paragraph';
import Meta from '../../components/atoms/Meta/Meta';

export default class Page extends Component {

  render() {

    let hero = null;
    let content = [];

    if(this.props.entity instanceof Entity) {
      if(this.props.entity.get('field_hero')) {
        hero = <Paragraph content={this.props.entity.get('field_hero')} drupal={this.props.drupal} />;
      }

      for(const paragraph of this.props.entity.get('field_content')) {
        if(!paragraph.get('behavior_settings')?.layout_paragraphs?.parent_uuid) {
          content.push(<Paragraph content={paragraph} drupal={this.props.drupal} />);
        }
      }
    }

    const meta = <Meta data={this.props.entity.get('meta')} />

    return (
      <div className="landing-page">
        {meta}

        {hero}

        <div className="content-wrapper container">
          <div className="row">
            <div className="page-title col-12">
            </div>
          </div>
          <div className="row">
            <div className="page-content col-12">
              {content}
            </div>
          </div>

        </div>
      </div>
    );
  }

}