import React, { Component } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import Menu from './drupal/Menu';
import Api from './drupal/Api';
import Connection from './drupal/Connection';
import RouteResolver from './pages/RouteResolver/RouteResolver';
import SearchPage from './pages/SearchPage/SearchPage';
import Entity from './drupal/Entity';
import Header from './components/layout/Header/Header';
import Footer from './components/layout/Footer/Footer';
import SiteInfo from './drupal/SiteInfo';

class App extends Component {

  state = {
    menuItems: {},
    hasHero: false,
    drupal: null,
    api: null,
    outlet: null
  };

  constructor(props) {
    super(props);

    const drupal = new Connection();
    const api = new Api(drupal);

    this.state = {
      menuItems: {},
      hasHero: false,
      drupal: drupal,
      api: api
    };
  }

  componentDidMount = async () => {
      const options = {
          'sort[menu_name][path]': 'menu_name',
          'sort[parent][path]': 'parent',
          'sort[weight][path]': 'weight',
          'sort[title][path]': 'title',
          'filter[name][path]': 'menu_name',
          'filter[name][value][1]': 'copyright',
          'filter[name][value][2]': 'footer',
          'filter[name][value][3]': 'main',
          'filter[name][value][4]': 'search',
          'filter[name][value][5]': 'teams',
          'filter[name][operator]': 'IN',

      };

      const menuResponse = await this.state.api.getEntities('menu_link_content', 'menu_link_content', options);
      const drupalMenus = new Menu(menuResponse.data.data);

      const outlet = await this.state.api.getEntity('outlet', 'outlet', SiteInfo.getOutlet());

      this.setState({
        menuItems: drupalMenus.getMenuTrees(),
        outlet: outlet
      });
  }

  componentDidUpdate = (prevProps) => {
      if(this.props.location !== prevProps.location) {
        window.scrollTo(0, 0);
      }
  }

  hasHero = (e) => {
    let heroField = null;
    
    if(e && e.props.entity instanceof Entity) {
      heroField = e.props.entity.getField('field_hero');
    }

    if(heroField && heroField.hasOwnProperty('data') && heroField.data !== null) {
      this.setState({
        hasHero: true
      });
    } else {
      this.setState({
        hasHero: false
      });
    }
  }

  render() {
    let pageClass = 'downpage';

    if(this.props.location.pathname === '/') {
        pageClass = 'front';
    }

    if(!this.state.outlet) {
      return (
        <></>
      );
    }

    return (
        <div className={ `page-wrapper ${pageClass}` }>
            <div className="page-top">
              <Header menu={this.getMenu} hasHero={this.state.hasHero} />

              <Switch>
                <Route path="/search" exact render={(props) => <SearchPage {...props} outlet={this.state.outlet} api={this.state.api} drupal={this.state.drupal} menu={this.getMenu} hasHero={this.hasHero} />} />
                <Route path="/customer/*" render={(props) => <RouteResolver {...props} outlet={this.state.outlet} api={this.state.api} drupal={this.state.drupal} hasHero={this.hasHero} ignoreUrlParts={[ '0' ]} />} />
                <Route path="/*" render={(props) => <RouteResolver {...props} outlet={this.state.outlet} api={this.state.api} drupal={this.state.drupal} menu={this.getMenu} hasHero={this.hasHero} />} />
              </Switch>
            </div>

            <Footer menu={this.getMenu} />
        </div>
    );
  }

  getMenu = (menuName) => {
    if(this.state.menuItems.hasOwnProperty(menuName)) {
        return this.state.menuItems[menuName];
    }

    return [];
  }

}

export default withRouter(App);
