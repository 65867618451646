import React, { Component } from 'react';
import { Image } from 'react-bootstrap';

import './GalleryItem.css';

export default class GalleryItem extends Component {

    render() {

        return (
            <div className="gallery-item">
                <Image src={this.props.image} alt={this.props.imageAlt} />
            </div>
        );

    }
}