import React, { Component } from 'react';

import './GallerySwitcherItem.css';

export default class GallerySwitcherItem extends Component {

    render() {
        let statusClass = 'inactive';
        if(this.props.status) {
            statusClass = 'active';
        }

        return(
            <div onClick={(e) => { this.props.handleClick(this, e) }} className={`gallery-switcher-item ${statusClass}`}>
                {this.props.galleryName}
            </div>
        );
    }

}