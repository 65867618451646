import React, { Component } from 'react';
import MarketCard from '../MarketCard/MarketCard';
import ContentGrid from '../../../layout/ContentGrid/ContentGrid';
import MarketMap from '../MarketMap/MarketMap';

export default class MarketOverviewContent extends Component {

    state = {
        markets: null
    };

    componentDidMount = async () => {
        const params = {
            'sort[type][path]': 'field_market_type.weight',
            'sort[title][path]': 'title'
        };
    
        const markets = await this.props.api.getEntities('node', 'market', params);

        this.setState({
            markets: markets.data
        });
    }

    render() {
        let marketCards = [];

        if(this.state.markets instanceof Object) {
            for(const market of this.state.markets.data) {
                marketCards.push(<MarketCard key={market.data.id} isActive={false} market={market} />);
            }
        }

        return (
            <div className="page-section padded">
                <ContentGrid small={12} medium={6} large={4}>{marketCards}</ContentGrid>
                <MarketMap />
            </div>
        );
    }

}