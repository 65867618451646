import React, { Component } from 'react';
import VirtualTourGrid from '../../building/VirtualTourGrid/VirtualTourGrid';

export default class VirtualTourList extends Component {

    state = {
        properties: {}
    };

    componentDidMount = async () => {
        const params = {
            'sort[title][path]': 'title',
            'filter[tour][condition][path]': 'field_buildings.field_virtual_tour_link.uri',
            'filter[tour][condition][operator]': 'IS NOT NULL'
        };
      
        const properties = await this.props.api.getEntities('node', 'property', params);
    
        this.setState({
            properties: properties.data
        });
      
    }

    render() {
        if(this.state.properties.hasOwnProperty('data')) {
            return (
                <div className="virtual-tour-list">

                    <VirtualTourGrid properties={this.state.properties} linkFieldOverride="field_virtual_tour" />

                </div>
            );
        }

        return (
            <></>
        );
    }

}
