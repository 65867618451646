import React, { Component } from 'react';
import BuildingGrid from '../../building/BuildingGrid/BuildingGrid';
import { withRouter } from 'react-router-dom';
import ContentGrid from '../../../layout/ContentGrid/ContentGrid';
import MarketCard from '../../market/MarketCard/MarketCard';

class CustomersPageContent extends Component {

    state = {
        markets: null,
        currentMarketId: null,
        buildings: null
    };

    componentDidMount = async () => {
        const query = new URLSearchParams(window.location.search);

        if(query.get('market') !== null && this.state.currentMarketId !== query.get('market')) {
            const buildings = await this.loadInitialMarket(query.get('market'));

            this.setState({
                currentMarketId: query.get('market'),
                buildings: buildings.data
            });
        } 

        this.getMarkets();

    }

    componentDidUpdate = async () => {
        const query = new URLSearchParams(window.location.search);

        if(query.get('market') !== null && this.state.currentMarketId !== query.get('market')) {
            const buildings = await this.loadInitialMarket(query.get('market'));

            this.setState({
                currentMarketId: query.get('market'),
                buildings: buildings
            });
        } 
        
        if(query.get('market') === null && this.state.currentMarketId !== null) {
            this.setState({
                currentMarketId: null,
                buildings: null
            });
        }
    }

    render() {

        let content = null;

        const query = new URLSearchParams(window.location.search);

        if(query.get('market') !== null && this.state.buildings !== null) {

            content = <BuildingGrid buildings={this.state.buildings} market={this.state.currentMarketId} isCustomerGrid={true} />;

        } else {

            let marketCards = [];

            if(this.state.markets instanceof Object) {
                for(const market of this.state.markets.data) {
                    marketCards.push(<MarketCard key={market.data.id} isActive={false} market={market} clickOverride={this.selectMarket} />);
                }
            }

            content = (
                <div className="page-section padded">
                    <ContentGrid small={12} medium={6} large={4}>{marketCards}</ContentGrid>
                </div>
            );
        }

        return content;
    }

    selectMarket = async (marketCard) => {
        const params = this.buildParams(marketCard.props.market.data.id);

        this.props.history.push(`/customers?market=${marketCard.props.market.data.id}`);

        this.getMarketProperties(params);
    }

    loadInitialMarket = async (marketId) => {
        let buildings = [];

        const params = this.buildParams(marketId);

        const properties = await this.getMarketProperties(params);

        for(const property of properties.data) {
            for(const building of property.get('field_buildings')) {
                if(building.get('field_outlet')?.get('field_status') || building.get('field_display_customer_default')) {
                    buildings.push(building);
                }
            }
        }

        return buildings;
    }

    buildParams = (marketId) => {
        return {
            'filter[market][path]': 'field_market.id',
            'filter[market][value]': marketId,
            'sort[title][path]': 'title',
            'include' : [
              'field_buildings',
              'field_buildings.field_teaser_image',
              'field_buildings.field_teaser_image.field_image_types',
              'field_buildings.field_teaser_image.field_media_image',
              'field_buildings.field_outlet',
            ].join(',')
        };
    }

    getMarketProperties = async (params) => {
        const properties = await this.props.api.getEntities('node', 'property', params);

        return properties.data;
    }

    getMarkets = async () => {
        const params = {
            'sort[type][path]': 'field_market_type.weight',
            'sort[title][path]': 'title',
            'include' : ['field_content',
              'field_teaser_image',
              'field_teaser_image.field_media_image',
            ].join(','),
        };

        const markets = await this.props.api.getEntities('node', 'market', params);

        this.setState({
            markets: markets.data
        });    
    }

}

export default withRouter(CustomersPageContent);