import React, { Component } from 'react';
import { Image } from 'react-bootstrap';
import Menu from '../../menu/Menu/Menu';
import ModalMenu from '../../menu/ModalMenu/ModalMenu';
import SearchBox from '../../ui/SearchBox/SearchBox';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons';

import './Header.css';

export default class Header extends Component {

    render() {
        let headerClasses = ['page-header'];
        let logoSrc = "/images/logo.svg";

        if (this.props.hasHero) {
            headerClasses.push('has-hero');
            logoSrc = "/images/logo-white.svg";
        }
        else {
            headerClasses.push('no-hero');
        }

        return (
            <header className={headerClasses.join(' ')}>
                <div className="container">
                    <div className="row page-header-content">
                        <div className="branding col-lg-10 col-10">
                            <Link to="/"><Image src={logoSrc} alt="Cousins Logo" /></Link>
                            <div className="main-menu d-none d-xl-block">
                                <Menu maxItems={6} depth={1} menuName="main" menu={this.props.menu} />
                            </div>
                        </div>

                        <div className="col-lg-2 col-2 search-wrapper">
                            <div className="d-none d-xl-block">
                                <SearchBox />
                            </div>

                            <div className="">
                                <ModalMenu menu={this.props.menu} menuName="main" depth={0} collapsible={true} expandTriggerExpanded={<FontAwesomeIcon icon={faChevronDown} />} expandTriggerCollapsed={<FontAwesomeIcon icon={faChevronRight} />} />
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }

}