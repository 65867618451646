import React, { Component } from 'react';
import Selector from '../../../ui/Selector/Selector/Selector';
import Entity from '../../../../drupal/Entity';
import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';

import './PropertyCard.css';

export default class PropertyCard extends Component {
    
    render() {

        if(this.props.entity instanceof Entity) {

            let title = this.props.entity.get('title');

            const building = this.props.entity.getFirst('field_buildings');

            const address = this.formatAddress(building.get('field_address'));

            if(this.props.selectorItems != null) {
                title = <Selector key={this.props.entity.get('id')} theme="light" items={this.props.selectorItems} currentItemId={this.props.entity.get('id')} currentItemTitle={title} handleSelect={this.props.handleSelect} />
            }

            let image = this.props.entity.get('field_teaser_image');

            if(image) {
                const imageUrl = image.get('field_media_image').get('links').medium_portrait.href;
                image = <Image src={imageUrl} alt={image.get('field_media_image').alt} />
            }

            return (
                <div className="property-card row">
                    <div className="details col col-12 col-md-7">
                        {title}

                        {address}

                        <div className="property-description">
                            {building.get('field_description')}
                        </div>

                        <div className="property-detail-link">
                            <Link className="button light highlighted" to={this.props.entity.get('url')}>View Property</Link>
                        </div>
                    </div>

                    <div className="image col col-12 col-md-5">
                        {image}
                    </div>
                </div>
            );
        }

        return (
            <></>
        );

    }

    formatAddress = (addressField) => {
        let line2 = null;

        if(addressField.address_line2 != null) {
            line2 = (
                <div className="address-line-2">
                    {addressField.address_line2}
                </div>
            );
        }

        return (
            <div className="address">
                <div className="address-line-1">
                    {addressField.address_line1}
                </div>

                {line2}

                <div className="city-state-zip">
                    {addressField.locality}, {addressField.administrative_area} {addressField.postal_code}
                </div>
            </div>
        )
    }

}