import React, { Component } from 'react';
import ArticleTeaser from '../ArticleTeaser/ArticleTeaser';
import InfiniteScroll from 'react-infinite-scroller';

export default class ArticlesList extends Component {

    state = {
        articles: [],
        hasMore: true,
        next: null
    };

    load = (page) => {

        if(this.state.next === null) {
            const params = {
                'sort[featured][path]': 'field_featured',
                'sort[featured][direction]': 'DESC',
                'sort[date][path]': 'field_date',
                'sort[date][direction]': 'DESC',
                'page[limit]': this.props.limit
            };

            this.props.api.getEntities('node', 'article', params).then(
                (response) => {
                    this.processResponse(response);
                }
            );
        } else {
            this.props.api.getResponseFromUrl(this.state.next).then(
                (response) => {
                    this.processResponse(response);
                }
            );
        }
    }

    processResponse = (response) => {

        let articles = response.data.data;

        let hasMore = false;
        let next = null;
        if(response.data.hasOwnProperty('links') && response.data.links.hasOwnProperty('next') && response.data.links.next.hasOwnProperty('href') && response.data.links.next.href != null) {
            hasMore = true;
            next = response.data.links.next.href;
        }

        this.setState({
            articles: this.state.articles.concat(articles),
            hasMore: hasMore,
            next: next
        });
    }

    render() {
        let items = [];

        if(this.state.articles.length > 0) {

            for(const item of this.state.articles) {

                const date = item.get('field_date');
                const title = item.get('title');
                const body = item.get('field_content');
                const linkUrl = item.get('url');

                items.push(<ArticleTeaser key={item.get('id')} title={title} date={date} linkUrl={linkUrl} body={body} />);
            }
        }

        return (
            <InfiniteScroll pageStart={0} loadMore={this.load} hasMore={this.state.hasMore}>
                <div className="articles-list page-section padded row">
                    <div className="col-12 articles">
                        {items}
                    </div>
                </div>
            </InfiniteScroll>
        );
    }

}