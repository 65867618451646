import React, { Component } from 'react';
import Menu from '../../../menu/Menu/Menu';
import Submenu from '../../../menu/Submenu/Submenu';

import './TeamSelector.css';

export default class TeamSelector extends Component {

    render = () => {
        let alignClass = `align-${this.props.align}`

        return (
            <div className={`team-selector page-section padded ${alignClass}`}>
                <div className="primary-team-menu">
                    <Menu menu={this.props.menu} menuName="teams" depth={1} />
                </div>

                <Submenu menu={this.props.menu} menuName="teams" location={this.props.location} />
            </div>
        );

    }

}