import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import MoreListItem from '../MoreListItem/MoreListItem';

import './MoreList.css';

export default class MoreList extends Component {

    state = {
        expanded: false,
    };

    componentDidUpdate = (prevProps) => {
        if(this.props !== prevProps && this.state.expanded) {
            this.setState({
                expanded: false
            });
        }
    }

    render() {
        let itemList = null;
        let icon = null;
        let key = null;

        if(this.props.items.length > 1) {
            icon = (<span className="more-list-toggle"><FontAwesomeIcon className="bounce" icon={faChevronDown} onClick={this.toggleItemList} /></span>);

            if(this.state.expanded) {
                icon = (<span className="more-list-toggle"><FontAwesomeIcon icon={faChevronUp} onClick={this.toggleItemList} /></span>);
                itemList = this.buildItemList(icon);
            }

            key = this.props.items[0].key;
        }

        return (
            <div className="more-list">
                <div className="more-list-header">
                    {icon}
                    <MoreListItem itemId={key}>{this.props.items[0]}</MoreListItem>
                </div>
                {itemList}
            </div>
        );
    }

    toggleItemList = () => {
        this.setState({
            expanded: !this.state.expanded
        });
    }

    buildItemList = (icon) => {
        let items = [];
        let i = 0;
        for(let item of this.props.items) {
            if(i > 0) {
                items.push(<MoreListItem itemId={item.key}>{item}</MoreListItem>);
            }
            i++;
        }

        return (
            <div className="item-list">
                <div className="more-list-header">
                    {icon}
                    <MoreListItem itemId={this.props.items[0].key}>{this.props.items[0]}</MoreListItem>
                </div>
                {items}
            </div>
        );
    }

}