import React, { Component } from 'react';
import BuildingGridItem from '../BuildingGridItem/BuildingGridItem';
import ContentGrid from '../../../layout/ContentGrid/ContentGrid';

export default class VirtualTourGrid extends Component {

    state = {
        items: []
    };

    componentDidMount = () => {
        let items = [];

        if(this.props.properties) {
            for(const property of this.props.properties) {

                for(let building of property.get('field_buildings')) {

                    if(building.get('field_virtual_tour_link')) {

                        items.push(<BuildingGridItem entity={property} building={building} />);

                    }

                }

            }

            this.setState({
                items: items
            });
        }
    }
    
    render() {

        return (
            <div className="virtual-tour-grid">
                <ContentGrid small={12} medium={4} large={3}>{this.state.items}</ContentGrid>
            </div>
        );

    }

}