import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Text from '../../../atoms/Text/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import './ArticleTeaser.css';

export default class ArticleTeaser extends Component {

    render() {
        const date = this.formatDate();
        const body = this.formatBodyTeaser();

        return (
            <div className="article-teaser">
                <Link to={this.props.linkUrl}>
                    <div className="date"><FontAwesomeIcon icon={faArrowRight} /><span className="date-value">{date}</span></div>
                    <h3>{this.props.title}</h3>
                    <Text text={body} />
                </Link>
            </div>
        )
    }

    formatDate = () => {
        const dateParts = this.props.date.split('-');
        return `${dateParts[1]}.${dateParts[2]}.${dateParts[0]}`;
    }

    formatBodyTeaser = () => {
        const length = 200;

        if(this.props.body[0].get('field_body').value.length > length) {
            return `${this.props.body[0].get('field_body').value.substring(0, length)}...`;
        } else {
            return this.props.body[0].get('field_body').value;
        }
    }

}