import React, { Component } from 'react';
import Modal from '../../ui/Modal/Modal';
import Menu from '../Menu/Menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { Image } from 'react-bootstrap';
import SearchBox from '../../ui/SearchBox/SearchBox';

import './ModalMenu.css';

export default class ModalMenu extends Component {

    constructor(props) {
        super(props);

        const origBodyOverflow = window.getComputedStyle(document.body).overflow;

        this.state = {
            modalOpen: false,
            origBodyOverflow: origBodyOverflow
        };
    }

    render() {
        let modal = null;
        
        if(this.state.modalOpen) {
            modal = this.getModalContent();
        }

        return (
            <div className="modal-menu">
                <div className="modal-menu-trigger" onClick={this.openModal}>
                    <FontAwesomeIcon icon={faBars} />
                </div>

                {modal}
            </div>
        );
    }

    getModalContent = () => {
        const modal = (
            <Modal handleClose={this.closeModal}>
                <div className="row">
                    <div className="col-12 col-lg-6 modal-menu-container">
                        <Image src="/images/logo-white.svg" alt="Cousins Logo" />

                        <Menu menuName={this.props.menuName} menu={this.props.menu} depth={this.props.depth} maxItems={this.props.maxItems} collapsible={this.props.collapsible} clickToExpand={this.props.clickToExpand} expandTriggerCollapsed={this.props.expandTriggerCollapsed} expandTriggerExpanded={this.props.expandTriggerExpanded} />

                        <div className="search-box-container">
                            <SearchBox theme="light" />
                        </div>
                    </div>
                </div>
            </Modal>
        );

        return modal;
    }

    openModal = () => {
        this.setState({
            modalOpen: true
        });

        document.body.style.overflow = 'hidden';
    }

    closeModal = () => {
        document.body.style.overflow = this.state.origBodyOverflow;

        this.setState({
            modalOpen: false
        });
    }

}