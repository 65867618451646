import React, { Component } from 'react';
import BuildingFact from '../BuildingFact/BuildingFact';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import Body from '../../../../atoms/Body/Body';
import { Button } from 'react-bootstrap';
import Include from '../../../../../drupal/Include';

import './BuildingFacts.css';

export default class BuildingFacts extends Component {

    state = {
        expanded: false
    };

    componentDidUpdate = (prevProps) => {
        if(this.props !== prevProps && this.state.expanded) {
            this.setState({
                expanded: false
            });
        }
    }

    render() {
        let additionalFacts = null;
        let toggleMore = null;
        let initialFacts = null;
        let buttons = null;
        let description = null;
        let amenities = null;
        let facts = [];

        if(this.props.currentBuilding instanceof Include) {

            for(let fact of this.props.currentBuilding.get('field_building_features')) {
                facts.push(<BuildingFact label={fact.get('field_name')} value={fact.get('field_value')} />);
            }

            if(facts.length > 4) {
                let icon = <FontAwesomeIcon className="bounce" onClick={this.toggleExpand} icon={faChevronDown} />

                if(this.state.expanded) {
                    icon = <FontAwesomeIcon onClick={this.toggleExpand} icon={faChevronUp} />

                    additionalFacts = (
                        <div className="additional-facts">
                            {facts.slice(4, facts.length)}
                        </div>
                    );
                }

                toggleMore = (
                    <div className="toggle">{icon}</div>
                );

                initialFacts = (
                    <div>
                        {facts.slice(0, 4)}
                        {additionalFacts}
                    </div>
                );

            } else {
                initialFacts = (
                    <div>{facts}</div>
                );
            }

            if(this.props.currentBuilding.get('field_description')) {
                description = (
                    <div className="description">
                        <Body text={this.props.currentBuilding.get('field_description')} />
                    </div>
                );
            }

            if(this.props.currentBuilding.get('field_amenities')) {
                amenities = (
                    <div className="amenities">
                        <div className="label">
                            Amenities
                        </div>
                        <Body text={this.props.currentBuilding.get('field_amenities')} />
                    </div>
                );
            }

            if(this.props.currentBuilding.get('field_links').length > 0) {
                let links = [];

                for(let link of this.props.currentBuilding.get('field_links')) {
                    links.push(<Button target="_blank" href={link.uri}>{link.title}</Button>);
                }

                buttons = (
                    <div className="buttons">
                        {links}
                    </div>
                );
            }
        }

        return (
            <div className="row building-facts">

                <div className="col-12 col-md-6">
                    <div className="facts">
                        {toggleMore}

                        {initialFacts}

                    </div>

                    {buttons}
                </div>

                <div className="col-12 col-md-6">
                    <div className="description-amenities">
                        {description}

                        {amenities}
                    </div>
                </div>

            </div>
        );
    }

    toggleExpand = () => {
        this.setState({
            expanded: !this.state.expanded
        });
    }

}