import React, { Component } from 'react';
import ContentGrid from '../../../layout/ContentGrid/ContentGrid';
import PositionTeaser from '../../position/PositionTeaser/PositionTeaser';
import Include from '../../../../drupal/Include';

import './TeamGrid.css';

export default class TeamGrid extends Component {

    render = () => {
        let items = [];

        for(const position of this.props.team.get('field_positions')) {
            const person = position.get('field_person');

            items.push(<PositionTeaser position={position} />);
        }

        return (
            <div className="team-grid">
                <ContentGrid small={12} medium={6} large={4}>{items}</ContentGrid>
            </div>
        );

    }
}