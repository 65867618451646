import React, { Component } from 'react';
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import './PropertyListItem.css';

export default class PropertyListItem extends Component {
    
    render() {

        const building = this.props.entity.getFirst('field_buildings');

        const address = this.formatAddress(building.get('field_address'));

        let image = this.props.entity.get('field_teaser_image');

        if(image) {
            const imageUrl = image.get('field_media_image').get('links').small_square.href;
            image = <Image src={imageUrl} alt={image.get('field_media_image').alt} />;
        }

        if(this.props.linkToProperty) {
            return (
                <Link to={this.props.entity.get('url')}>
                    <div className="property-list-item row" onClick={this.props.handleSelect}>
                        <div className="image co-2">
                            {image}
                        </div>

                        <div className="details col col-10">
                            <h3>{this.props.entity.get('title')}</h3>
                            {address}
                        </div>
                    </div>

                </Link>
            );
        }

        return (
            <div className="property-list-item row" onClick={this.props.handleSelect}>
                <div className="image col col-2">
                    {image}
                </div>

                <div className="details col col-10">
                    <h3>{this.props.entity.get('title')}</h3>
                    {address}
                </div>
            </div>

        );

    }

    formatAddress = (addressField) => {
        let line2 = null;

        if(addressField.address_line2 != null) {
            line2 = (
                <div className="address-line-2">
                    {addressField.address_line2}
                </div>
            );
        }

        return (
            <div className="address">
                <div className="address-line-1">
                    {addressField.address_line1}
                </div>

                {line2}

                <div className="city-state-zip">
                    {addressField.locality}, {addressField.administrative_area} {addressField.postal_code}
                </div>
            </div>
        )
    }

}