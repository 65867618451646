import React, { Component } from 'react';
import Selector from '../../../ui/Selector/Selector/Selector';
import BuildingMini from '../BuildingMini/BuildingMini';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import './BuildingSwitcher.css';
import Include from '../../../../drupal/Include';

export default class BuildingSwitcher extends Component {

    state = {
        expanded: false,
    };

    render() {
        if(this.props.currentBuilding instanceof Include) {
            const address = this.constructAddress();
            let buildingSelect = null;
            let buildingCountLabel = null;
            const buildingStatus = this.getBuildingStatus();

            const buildingCount = this.props.entity.get('field_buildings').length;
            if(buildingCount > 1) {
                buildingSelect = this.buildBuildingSelect();

                buildingCountLabel = (
                    <h3 className="building-count" onClick={this.toggleSelector} onMouseEnter={this.showSelector}>
                        {buildingCount} Buildings <FontAwesomeIcon icon={faChevronRight} className="bounce-right" />
                    </h3>
                );
            }

            return (
                <div className="building-switcher">

                    <div className="current-building">

                        {buildingCountLabel}

                        <div className="building-selector-wrapper">
                            {buildingSelect}

                            <div className="building-address">{address}</div>

                            {buildingStatus}
                        </div>
                        
                    </div>
                </div>
            );
        }

        return null;
    }

    constructAddress = () => {
        let addressParts = [];

        if(this.props.currentBuilding.get('field_address').address_line1 !== null) {
            addressParts.push(<div key="address-line1" className="address-line1">{this.props.currentBuilding.get('field_address').address_line1}</div>);
        }

        if(this.props.currentBuilding.get('field_address').address_line2 !== null) {
            addressParts.push(<div key="address-line2" className="address-line2">{this.props.currentBuilding.get('field_address').address_line2}</div>);
        }

        if(this.props.currentBuilding.get('field_address').locality !== null && this.props.currentBuilding.get('field_address').administrative_area !== null && this.props.currentBuilding.get('field_address').postal_code !== null) {
            addressParts.push(<div key="city-state-zip" className="city-state-zip">{this.props.currentBuilding.get('field_address').locality}, {this.props.currentBuilding.get('field_address').administrative_area} {this.props.currentBuilding.get('field_address').postal_code}</div>);
        }

        return addressParts;
    }

    buildBuildingSelect = () => {
        let items = [];

        for(let building of this.props.entity.get('field_buildings')) {
            items.push(<BuildingMini key={building.get('id')} building={building} />);
        }

        return (
            <Selector bounce={true} showIcon={false} direction="horizontal" handleSelect={this.handleBuildingChange} currentItemTitle={this.props.currentBuilding.get('field_name')} currentItemId={this.props.currentBuilding.get('id')} items={items} toggleCallback={this.toggleSelector} showCallback={this.showSelector} expanded={this.state.expanded} key="building-switcher-selector" />
        );
    }

    handleBuildingChange = (selectorItem) => {
        this.setState({
            expanded: false
        });

        this.props.handleBuildingChange(selectorItem);
    }

    getBuildingStatus = () => {
        let buildingStatusMarkup = null;
        const buildingStatus = this.props.currentBuilding.get('field_building_status');

        if(buildingStatus.get('id') !== '3fe5b6df-c423-4a65-bde7-07d19397e024') {
            buildingStatusMarkup = (
                <div className="building-status">
                    {buildingStatus.get('name')}
                </div>
            );
        }

        return buildingStatusMarkup;
    }

    toggleSelector = () => {
        this.setState({
            expanded: !this.state.expanded
        });
    }

    showSelector = () => {
        this.setState({
            expanded: true
        });
    }
}