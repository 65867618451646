export default class Connection {

    baseUrl = "https://data.cousins.com";

    jsonApiBase = "/api/";

    domainOverride = null;

    constructor() {
        let currentHost = window.location.host;
        if (currentHost.includes('.platformsh.site')) {
            const uriParts = currentHost.split(".").slice(-4);
            this.baseUrl = 'https://data.' + uriParts.join(".");
        }
        else if (currentHost.includes('localhost')) {
            // Not a typical use case.  Would rather just pull from production data.
            // If you need a local backend, here is where to do it.
            this.baseUrl = 'https://cousins.lndo.site';
            this.domainOverride = null;
        }
    }

    getBaseUrl = () => {
        return this.baseUrl;
    }

    getJsonApiBase = () => {
        return this.jsonApiBase;
    }

    getDomainOverride = () => {
        return this.domainOverride;
    }

}