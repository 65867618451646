import React, { Component } from 'react';
import { Image } from 'react-bootstrap';

import './PropertyGridItem.css';

export default class PropertyGridItem extends Component {

    render() {

        let image = null;
        const imageMedia = this.props.entity.get('field_teaser_image');

        if(imageMedia) {
            const imageUrl = imageMedia.get('field_media_image').get('links').medium_square.href;
            image = <Image src={imageUrl} alt={imageMedia.get('field_media_image').alt} />;
        }

        let linkUrl = this.props.entity.get('url');
        if(this.props.linkFieldOverride) {
            linkUrl = this.props.entity.get(this.props.linkFieldOverride).uri;
        }

        if(this.props.isCustomerLink) {
            linkUrl = `/customer${linkUrl}`;
        }

        let themeClass = 'dark';
        if(this.props.theme) {
            themeClass = this.props.theme;
        }

        return (
            <div className={`property-grid-item ${themeClass}`}>
                <a href={linkUrl}>
                    {image}
                    {this.props.entity.get('title')}
                </a>
            </div>
        );

    }

}