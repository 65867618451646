import React, { Component } from 'react';
import ContentGrid from '../../../layout/ContentGrid/ContentGrid';
import MarketCard from '../MarketCard/MarketCard';

export default class MarketGrid extends Component {

    state = {
        markets: null
    };

    componentDidMount = async () => {
        const params = {
            'sort[type][path]': 'field_market_type.weight',
            'sort[title][path]': 'title',
        };

        const markets = await this.props.api.getEntities('node', 'market', params);

        this.setState({
            markets: markets.data
        });
    }

    render() {
        if(this.state.markets) {
            let items = [];

            for(const item of this.state.markets.data) {
                items.push(<MarketCard key={item.id} isActive={false} market={item} />);
            }

            return (
                <div className="market-grid page-section padded">
                    <ContentGrid small={12} medium={6} large={4}>
                        {items}
                    </ContentGrid>
                </div>
            );
        } else {
            return (
                <></>
            );
        }
    }

}