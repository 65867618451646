import React, { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import SearchResult from '../../components/content/search/SearchResult/SearchResult';
import Entity from '../../drupal/Entity';
import { Helmet } from 'react-helmet';
import Spinner from '../../components/ui/Spinner/Spinner';

import './SearchPage.css';

export default class SearchPage extends Component {

    state = {
        keywords: null,
        results: [],
        hasMore: true,
        next: null,
        totalResults: 0,
        last: null
    };

    componentDidMount = () => {
        this.props.hasHero();
    }

    componentDidUpdate = (prevProps) => {

        const query = new URLSearchParams(window.location.search);

        if(query.get('keywords') !== this.state.keywords) {
            this.setState({
                keywords: query.get('keywords'),
                results: [],
                hasMore: true,
                next: null,
                totalResults: null,
                last: null
            });
        }
    }

    render() {

        let items = [];

        if(this.state.results.length > 0) {

            for(const item of this.state.results) {

                if(item instanceof Entity) {

                    const title = item.get('title');
                    const linkUrl = item.get('url');

                    items.push(<SearchResult key={item.get('id')} title={title} linkUrl={linkUrl} teaser={item.get('field_teaser')} type={item.get('type')} />);
                }
            }
        }

        let spinner = null;
        if(this.state.hasMore) {
            spinner = <Spinner />;
        }

        let headline = (
            <h4>{this.state.totalResults} {this.state.totalResults === 1 ? 'result' : 'results'} for <i>'{this.state.keywords}'</i></h4>
        )

        if(this.state.totalResults === 0) {
            headline = (
                <h4>There are no results for the keywords <i>'{this.state.keywords}'</i>.</h4>
            );
        }

        return (
            <div className="container">
                <Helmet>
                    <title>Search Results | {this.props.outlet.get('field_title')}</title>
                </Helmet>
                
                <InfiniteScroll pageStart={0} loadMore={this.load} hasMore={this.state.hasMore} initialLoad={true}>
                    <div className="search-page page-section padded row">
                        <div className="col-12 search-results">
                            <h2>Search Results</h2>
                            {headline}
                            {items}
                        </div>

                        {spinner}
                    </div>
                </InfiniteScroll>
            </div>
        );
    }

    load = async (page) => {

        if(this.state.keywords != null) {

            let response = null;
            let callId = null;

            if(this.state.next === null) {
                const params = {
                    'filter[fulltext]': this.state.keywords,
                    'page[limit]': 20
                };

                response = await this.props.api.getEntities('index', 'content', params);
                callId = 'init';
            } else {
                response = await this.props.api.getResponseFromUrl(this.state.next);
                callId = this.state.next;
            }

            this.processResponse(response, callId);

        }
    }

    processResponse = (response, callId) => {

        let results = response.data.data;

        let hasMore = false;
        let next = null;
        if(response.data.hasOwnProperty('links') && response.data.links.hasOwnProperty('next') && response.data.links.next.hasOwnProperty('href') && response.data.links.next.href != null) {
            hasMore = true;
            next = response.data.links.next.href;
        }

        if(callId !== this.state.last) {
            this.setState({
                results: this.state.results.concat(results),
                hasMore: hasMore,
                next: next,
                totalResults: response.data.meta.count,
                last: callId
            });
        }
    }

}