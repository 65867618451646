import React, { Component } from 'react';
import ArticleTeaser from '../ArticleTeaser/ArticleTeaser';
import { Link } from 'react-router-dom';

import './RecentArticlesList.css';

export default class RecentArticlesList extends Component {

    state = {
        articles: null
    };

    componentDidMount = async () => {
        const params = {
            'sort[featured][path]': 'field_featured',
            'sort[featured][direction]': 'DESC',
            'sort[date][path]': 'field_date',
            'sort[date][direction]': 'DESC',
            'page[limit]': this.props.limit
        };

        const articles = await this.props.api.getEntities('node', 'article', params);

        this.setState({
            articles: articles.data
        });
    }

    render() {
        if(this.state.articles) {
            let items = [];

            for(const item of this.state.articles.data) {
                const date = item.get('field_date');
                const title = item.get('title');
                const body = item.get('field_content');
                const linkUrl = item.get('url');

                items.push(<ArticleTeaser key={item.get('id')} title={title} date={date} linkUrl={linkUrl} body={body} />);
            }

            let headlineClass = 'normal';
            let headlineColClass = 'col-lg-12';
            let contentColClass = 'col-lg-12';

            if(this.props.fancyTitle) {
                headlineClass = 'vertical';
                headlineColClass = 'col-lg-2';
                contentColClass = 'col-lg-10';
            }

            return (
                <div className="recent-articles-list page-section padded row">
                    <div className={`headline col-12 ${headlineColClass} ${headlineClass}`}>
                        <hr />

                        <h2>Latest News</h2>
                    </div>

                    <div className={`articles col-12 ${contentColClass} articles`}>
                        {items}
                    </div>
                    
                    <div className="col-12 more-link">
                        <Link to="/news">View All News</Link>
                    </div>
                </div>
            );
        } else {
            return (
                <></>
            );
        }
    }

}