import React, { Component } from 'react';
import Paragraph from '../../components/content/paragraphs/Paragraph/Paragraph';
import TeamGrid from '../../components/content/team/TeamGrid/TeamGrid';
import TeamSelector from '../../components/content/team/TeamSelector/TeamSelector';
import Meta from '../../components/atoms/Meta/Meta';

export default class TeamDetailPage extends Component {

    render = () => {
        let content = [];

        for(const item of this.props.entity.get('field_content')) {
            if(!item.get('behavior_settings')?.layout_paragraphs?.parent_uuid) {
                content.push(<Paragraph content={item} drupal={this.props.drupal} />);
            }
        }

        const meta = <Meta data={this.props.entity.get('meta')} />

        return (
            <div className="content-wrapper team-detail-page container page-content">
                {meta}

                {content}

                <TeamSelector menu={this.props.menu} align="center" location={this.props.location} />

                <TeamGrid team={this.props.entity} />
            </div>
        );

    }
}