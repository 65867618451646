import React from 'react';
// import ContentPage from '../pages/ContentPage/ContentPage';
import LandingPage from '../pages/LandingPage/LandingPage';
import PropertyDetailPage from '../pages/PropertyDetailPage/PropertyDetailPage';
import Page from '../pages/Page/Page';
import MarketDetailPage from '../pages/MarketDetailPage/MarketDetailPage';
import ArticleDetailPage from '../pages/ArticleDetailPage/ArticleDetailPage';
import PersonDetailPage from '../pages/PersonDetailPage/PersonDetailPage';
import TeamDetailPage from '../pages/TeamDetailPage/TeamDetailPage';

export default class ComponentMap {

    static map = [
        {
            entityType: 'node',
            entityBundle: [
                'landing_page'
            ],
            component: <LandingPage />
        },
        {
            entityType: 'node',
            entityBundle: [
                'property'
            ],
            component: <PropertyDetailPage />
        },
        {
            entityType: 'node',
            entityBundle: [
                'page'
            ],
            component: <Page />
        },
        {
            entityType: 'node',
            entityBundle: [
                'market'
            ],
            component: <MarketDetailPage />
        },
        {
            entityType: 'node',
            entityBundle: [
                'article'
            ],
            component: <ArticleDetailPage />
        },
        {
            entityType: 'node',
            entityBundle: [
                'person'
            ],
            component: <PersonDetailPage />
        },
        {
            entityType: 'node',
            entityBundle: [
                'team'
            ],
            component: <TeamDetailPage />
        }
    ];
}
