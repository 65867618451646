import React, { Component } from 'react';
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import './PositionTeaser.css';
import PhoneLink from "../../../atoms/PhoneLink/PhoneLink";

export default class PositionTeaser extends Component {

    render() {

        const person = this.props.position.get('field_person');

        let image = null;

        if(this.props.position.get('field_show_image') && person.get('field_image')) {
            const personImageUrl = person.get('field_image').get('field_media_image').get('links').medium_square.href;

            if(person.get('field_disable_detail_page')) {
                image = (
                    <div className="person-image">
                        <Image src={personImageUrl} alt={person.get('field_image').get('field_media_image').alt} />
                    </div>
                );
            } else {
                image = (
                    <div className="person-image">
                        <Link to={person.get('url')}><Image src={personImageUrl} alt={person.get('field_image').get('field_media_image').alt} /></Link>
                    </div>
                );
            }
        }

        let email = null;
        if(this.props.position.get('field_show_email')) {
            const emailHref = 'mailto:' + person.get('field_email');
            email = (
                <div className="contact email">
                    <a href={emailHref}>{person.get('field_email')}</a>
                </div>
            );
        }

        let phone = null;
        if(this.props.position.get('field_show_phone') && person.get('field_phone')) {
            phone = (
                <div className="contact phone"><PhoneLink number={person.get('field_phone')} /></div>
            );
        }

        let teaser = null;
        if(this.props.position.get('field_show_teaser') && person.get('field_teaser')) {
            teaser = (
                <div className="teaser">
                    {this.formatBodyTeaser(person.get('field_teaser'))} <Link to={person.get('url')}>Read More...</Link>
                </div>
            );
        }

        let name = (
            <div className="person-name">{person.get('title')}</div>
        );

        if(!person.get('field_disable_detail_page')) {
            name = (
                <Link to={person.get('url')}>{name}</Link>
            )
        }

        return (
            <div className="position-teaser">
                {image}
                {name}
                <div className="job-title">{this.props.position.get('field_job_title')}</div>
                {email}
                {phone}
                {teaser}
            </div>
        );
    }

    formatBodyTeaser = (text) => {
        const length = 100;

        if(text.length > length) {
            return text.substring(0, length);
        } else {
            return text;
        }
    }

}
