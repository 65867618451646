import React, { Component } from 'react';
import Slider from 'react-slick';
import GalleryItem from '../GalleryItem/GalleryItem';
import GallerySwitcherItem from '../GallerySwitcherItem/GallerySwitcherItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import File from '../../../media/File/File';
import MoreList from '../../../../ui/MoreList/MoreList/MoreList';
import Connection from '../../../../../drupal/Connection';

import './Gallery.css';

export default class Gallery extends Component {

    state = {
        currentGalleryItems: null,
        galleries: [],
        currentGallery: null
    };

    componentDidMount = () => {
        const currentGalleryItems = this.buildGalleryItems(this.props.galleries[0]);

        this.setState({
            currentGalleryItems: currentGalleryItems,
            galleries: this.props.galleries,
            currentGallery: this.props.galleries[0]
        });
    }
    
    render() {

        if(this.state.currentGalleryItems !== null) {

            const sliderSettings = {
                dots: true,
                arrows: false,
                infinite: false,
                slidesToScroll: 1,
                variableWidth: true,
                adaptiveHeight: true
            };

            let galleries = [];
            for(let gallery of this.state.galleries) {
                let active = false;
                if(gallery.get('id') === this.state.currentGallery.get('id')) {
                    active = true;
                }

                galleries.push(
                    <GallerySwitcherItem status={active} galleryId={gallery.get('id')} gallery={gallery} galleryName={gallery.get('field_name')} handleClick={this.switchGallery} />
                );
            }

            let files = [];
            for(let media of this.props.currentBuilding.get('field_attachments')) {
                const file = media.get('field_media_file');
                const baseUrl = this.props.drupal.getBaseUrl().replace(/\/$/, "");
                const description = media.get('field_media_file').description;
                files.push(<File key={file.get('id')} url={baseUrl + file.get('uri').url} description={description} />);
            }



            return (
                <div className="gallery">

                    <div className="container">
                        <div className="row container">

                            <div className="gallery-switcher col-12 col-lg-9 order-2 order-lg-1">
                                {galleries}
                            </div>

                            <div className="file-switcher col-12 col-lg-3 order-1 order-lg-2">
                                <MoreList items={files} />
                            </div>
                        </div>
                    </div>

                    <div className="row gallery-carousel">

                        <div className="col-lg-3 arrows d-none d-lg-block">
                            <div onClick={this.next} className="arrow-next">
                                <FontAwesomeIcon icon={faChevronRight} />
                            </div>

                            <div onClick={this.prev} className="arrow-prev">
                                <FontAwesomeIcon icon={faChevronLeft} />
                            </div>
                        </div>

                        <div className="col-lg-9 col-12 carousel">
                            <Slider {...sliderSettings} ref={(c) => { this.slider = c }}>{this.state.currentGalleryItems}</Slider>
                        </div>
                    </div>

                </div>
            );
        }

        return (
            <></>
        );

    }

    switchGallery = (gallerySwitcherItem) => {
        const galleryItems = this.buildGalleryItems(gallerySwitcherItem.props.gallery);

        this.setState({
            currentGalleryItems: galleryItems,
            currentGallery: gallerySwitcherItem.props.gallery,
        });
    }

    buildGalleryItems = (gallery) => {
        let items = [];

        for(let media of gallery.get('field_images')) {
            const image = media.get('field_media_image');
            items.push(<GalleryItem image={image.get('links').medium_scale_only.href} imageAlt={media.get('field_media_image').alt} />)
        }

        return items;
    }

    getGalleryInfo = () => {
        let galleryInfo = [];

        for(let galleryRef of this.props.galleries) {
            const gallery = this.props.entity.getInclude(galleryRef.id);

            galleryInfo.push({
                id: gallery.id,
                name: gallery.get('field_name')
            });
        }

        return galleryInfo;
    }

    next = () => {
        this.slider.slickNext();
    }

    prev = () => {
        this.slider.slickPrev();
    }

}