import React, { Component } from 'react';
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import './MarketCard.css';

export default class MarketCard extends Component {

    render() {
        const imageUrl = this.props.market.get('field_teaser_image').get('field_media_image').get('links').medium_square.href;
        const alt = this.props.market.get('field_teaser_image').get('field_media_image').alt;
        const image = <Image src={imageUrl} alt={alt} />;
        const title = (
            <h3>{this.props.market.get('title')}</h3>
        );

        let activeClass = '';

        if(this.props.isActive) {
            activeClass = 'active';
        }

        let content = (
            <>
                {image}
                {title}
                <div className="active-indicator"></div>
            </>
        );

        if(this.props.clickOverride) {
            content = (
                <div onClick={(e) => { this.props.clickOverride(this, e) }}>
                    {content}
                </div>
            );
        } else {
            content = (
                <Link to={this.props.market.get('url')}>
                    {content}
                </Link>
            );
        }

        return (
            <div role="button" className={`market-card ${activeClass}`}>
                <div className="content">
                    {content}
                </div>
            </div>
        );
    }

}
