import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';

import './File.css';

export default class File extends Component {

    render() {
        let description = this.props.description;

        if(description === null) {
            description = "Download File";
        }

        return (
            <div className="file">
                <a target="_blank" rel="noopener noreferrer" href={this.props.url}>{description} <span className="icon"><FontAwesomeIcon icon={faFileDownload} /></span></a>
            </div>
        );
    }

}