import React, { Component } from 'react';
import ComponentMap from '../../drupal/ComponentMap';
import Entity from '../../drupal/Entity';

export default class RouteResolver extends Component {

    state = {
        component: null,
        loading: true
    };

    componentDidUpdate = (prevProps) => {
        if(window.location.pathname !== prevProps.location.pathname) {
            this.resolvePath();
        }
    }

    componentDidMount = () => {
        this.resolvePath();
    }

    resolvePath = async () => { 
        let entity = null;
        let meta = null;

        if(this.props.location.pathname === '/') {
            const nodeBundleParts = this.props.outlet.get('field_front_page').get('type').split("--");

            meta = {
                type: nodeBundleParts[0],
                bundle: nodeBundleParts[1],
                uuid: this.props.outlet.get('field_front_page').get('id')
            };
        } else {

            const params = {
                path: this.props.location.pathname,
                _format: 'json'
            };

            if(this.props.ignoreUrlParts) {
                const urlParts = params.path.replace(/^\/+|\/+$/g, '').split('/');

                let keepUrlParts = [];
                for(let i in urlParts) {
                    if(!this.props.ignoreUrlParts.includes(i)) {
                        keepUrlParts.push(urlParts[i]);
                    }
                }

                params.path = `${keepUrlParts.join('/')}`;
            }

            const response = await this.props.api.resolvePath(params);

            if(response.hasOwnProperty('data') && response.data.hasOwnProperty('entity')) {
                meta = this.props.api.getEntityMetaFromPath(response);
            } else if (response.hasOwnProperty('status') && response.status === 404) {
                const nodeBundleParts = this.props.outlet.get('field_404_page').get('type').split("--");

                meta = {
                    type: nodeBundleParts[0],
                    bundle: nodeBundleParts[1],
                    uuid: this.props.outlet.get('field_404_page').get('id')
                };
            }
        }

        entity = await this.props.api.getEntity(meta.type, meta.bundle, meta.uuid);
        
        if(entity instanceof Entity) {

            const componentMeta = ComponentMap.map.find(element => element.entityType === meta.type && element.entityBundle.includes(meta.bundle));

            if(componentMeta != null) {
                const component = componentMeta.component;
        
                this.setState({
                    component: React.cloneElement(
                        component,
                        {
                            entity: entity,
                            key: entity.data.data.id,
                            menu: this.props.menu,
                            location: this.props.location,
                            api: this.props.api,
                            drupal: this.props.drupal,
                            outlet: this.props.outlet
                        }
                    ),
                    loading: false
                });

                this.props.hasHero(this.state.component);
            }
        }
    }
    
    render = () => {
        if(this.state.loading) {
            return (
                <></>
            );
        }

        if(this.state.component) {
            return (
                <>
                    {this.state.component}
                </>
            );
        }
        
        return (<></>);

    }


}