import React, { Component } from 'react';
import SiteInfo from '../../drupal/SiteInfo';
import Entity from '../../drupal/Entity';
import Paragraph from '../../components/content/paragraphs/Paragraph/Paragraph';
import SpecialPageContent from '../SpecialPageContent/SpecialPageContent';
import Meta from '../../components/atoms/Meta/Meta';

export default class ContentPage extends Component {

  render() {

    let hero = null;
    let content = [];

    if(this.props.entity instanceof Entity) {
      if(this.props.entity.get('field_hero')) {
        hero = <Paragraph key="hero" content={this.props.entity.get('field_hero')} drupal={this.props.drupal} />;
      }

      for(const paragraph of this.props.entity.get('field_content')) {
        if(!paragraph.get('behavior_settings')?.layout_paragraphs?.parent_uuid) {
          content.push(<Paragraph key={paragraph.get('id')} content={paragraph} drupal={this.props.drupal} />);
        }
      }
    }

    let specialPageContent = null;

    if(this.isSpecialPage()) {
        specialPageContent = (
          <div className="row">
            <div className="col-12">
              {this.renderSpecialPageContent()}
            </div>
          </div>
        );
    }

    const meta = <Meta data={this.props.entity.get('meta')} />

    return (
      <div className="landing-page">
        {meta}

        {hero}

        <div className="content-wrapper container">
          <div className="row">
            <div className="page-title col-12">
            </div>
          </div>
          <div className="row">
            <div className="page-content col-12">
              {content}
            </div>
          </div>

          {specialPageContent}

        </div>
      </div>
    );
  }

  isSpecialPage = () => {
    const specialPage = Object.values(SiteInfo.UUIDS).find(value => value === this.props.entity.get('id'));

    if(specialPage != null) {
      return true;
    }

    return false;
  }

  renderSpecialPageContent = () => {
    const specialPageName = Object.keys(SiteInfo.UUIDS).find(key => SiteInfo.UUIDS[key] === this.props.entity.get('id'));

    return <SpecialPageContent api={this.props.api} page={specialPageName} />
  }
}