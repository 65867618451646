import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import SelectorItem from '../SelectorItem/SelectorItem';

import './Selector.css';

export default class Selector extends Component {

    state = {
        expanded: false,
        currentItemId: this.props.currentItemId
    };

    componentDidUpdate = () => {
        if(this.props.hasOwnProperty('expanded') && this.state.expanded !== this.props.expanded) {
            this.setState({
                expanded: !this.state.expanded
            });
        }
    }

    render() {
        let itemList = null;
        let icon = null;
        let theme = 'dark';
        let direction = 'vertical';
        let collapsedIcon = faChevronDown;
        let expandedIcon = faChevronUp;
        const showIcon = (this.props.showIcon === undefined) ? true : this.props.showIcon;

        if(this.props.direction) {
            direction = this.props.direction;
        }

        let bounceClass = 'bounce';
        if(direction === 'horizontal') {
            collapsedIcon = faChevronRight;
            expandedIcon = faChevronRight;
            bounceClass = 'bounce-right';
        }

        if(showIcon && this.props.items.length > 1) {
            icon = <FontAwesomeIcon className={bounceClass} icon={collapsedIcon} />;
        }

        if(this.state.expanded) {

            if(showIcon && this.props.items.length > 1) {
                icon = <FontAwesomeIcon className={bounceClass} icon={expandedIcon} />;
            }
            
            itemList = this.buildItemList(icon);
        }

        if(this.props.theme) {
            theme = this.props.theme;
        }

        return (
            <div className={`selector ${theme} ${direction}`}>
                <div className="selector-header" onClick={this.toggleItemList} onMouseEnter={this.showItemList}>
                    {icon}
                    <h3>{this.props.currentItemTitle}</h3>
                </div>
                {itemList}
            </div>
        );
    }

    toggleItemList = () => {
        if(this.props.hasOwnProperty('toggleCallback')) {
            this.props.toggleCallback();
        }

        this.setState({
            expanded: !this.state.expanded
        });
    }

    showItemList = () => {
        if(this.props.hasOwnProperty('showCallback')) {
            this.props.showCallback();
        }

        this.setState({
            expanded: true
        });
    }

    buildItemList = (icon) => {
        let items = [];

        for(let item of this.props.items) {
            if(item.key !== this.state.currentItemId) {
                items.push(<SelectorItem itemId={item.key} handleSelect={this.handleSelect}>{item}</SelectorItem>);
            }
        }

        return (
            <div className="item-list">
                <div className="selector-header">
                    {icon}
                    <h3>{this.props.currentItemTitle}</h3>
                </div>
                {items}
            </div>
        );
    }

    handleSelect = (selectorItem) => {
        this.props.handleSelect(selectorItem);
        this.setState({
            expanded: false,
            currentItemId: selectorItem.props.itemId
        });
    }

}