import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import Menu from '../../menu/Menu/Menu';

import './Footer.css';

export default class Footer extends Component {

    render() {

        const year = new Date().getFullYear();

        return (
            <footer className="page-footer">
                <div className="pre-footer">
                    <div className="flex-container offset-up">
                            <div className="offset-down flexible background-prime">
                                <div className="flex-content limit-half-width align-right">
                                    <h2>Career Opportunities</h2>
                                    <Link to="/careers">Begin your journey here</Link>
                                </div>
                            </div>
                            <div className="flexible background-prime-dark">
                                <div className="flex-content limit-half-width align-left">
                                    <h2>Contact Us</h2>
                                    <div>3344 Peachtree Rd NE, Suite 1800, Atlanta, GA 30326</div>
                                    <div><a href={"tel:4044071000"}>(404) 407-1000</a></div>
                                </div>
                            </div>
                    </div>
                </div>
                <div className="footer">
                    <div className="container">
                        <div className="row footer-content">
                            <div className="col-12 col-lg-3">
                                <div className="icon-logo">
                                    <div className="logo-copyright">
                                        <Image src="/images/logo-icon-white.svg" alt="Cousins Logo" /> © Cousins Properties {year}
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-lg-3">
                                <div className="copyright-menu">
                                    <Menu depth={1} maxItems={2} menu={this.props.menu} menuName="copyright" />
                                </div>
                                
                            </div>

                            <div className="col-12 col-lg-6 footer-menu">
                                <Menu depth={1} menu={this.props.menu} menuName="footer" />
                            </div>
                        </div>

                        <div className="row fine-print">
                            <div className="col">
                                <a href="https://www.coolbluei.com">Powered by Cool Blue Interactive</a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }

}