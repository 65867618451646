import React, { Component } from 'react';
import ContentGrid from '../../components/layout/ContentGrid/ContentGrid';
import MarketCard from '../../components/content/market/MarketCard/MarketCard';
import Entity from '../../drupal/Entity';
import Paragraph from '../../components/content/paragraphs/Paragraph/Paragraph';
import PositionGrid from '../../components/content/position/PositionGrid/PositionGrid';
import PropertyPicker from '../../components/content/property/PropertyPicker/PropertyPicker';
import Meta from '../../components/atoms/Meta/Meta';

export default class MarketDetailPage extends Component {

  state = {
    markets: null,
    properties: {}
  };

  componentDidMount = async () => {
    const marketParams = {
      'sort[type][path]': 'field_market_type.weight',
      'sort[title][path]': 'title',
    };

    const markets = await this.props.api.getEntities('node', 'market', marketParams);

    const propertyParams = {
      'sort[title][path]': 'title',
      'filter[market][path]': 'field_market.id',
      'filter[market][value]': this.props.entity.get('id'),
      'include': 'field_teaser_image, field_teaser_image.field_media_image, field_buildings'
    };

    const properties = await this.props.api.getEntities('node', 'property', propertyParams);

    this.setState({
      markets: markets.data,
      properties: properties.data
    });
  }

  render() {
    let marketCards = [];
    let hero = null;
    let positionGrid = null;
    let marketId = null;

    if(this.props.entity instanceof Entity) {

      if(this.state.markets instanceof Object) {
        for(const market of this.state.markets.data) {

          let isActiveMarket = false;

          if(market.data.id === this.props.entity.data.data.id) {
            isActiveMarket = true;
            marketId = market.data.id;
          }

          marketCards.push(<MarketCard key={market.data.id} isActive={isActiveMarket} market={market} />);
        }
      }

      const heroData = this.props.entity.get('field_hero');

      if(heroData) {
        hero = <Paragraph content={heroData} drupal={this.props.drupal} />;
      }

      positionGrid = (
          <div className="page-section padded">
              <PositionGrid featured={false} positions={this.props.entity.get('field_positions')} />
          </div>
      );

    }

    const meta = <Meta data={this.props.entity.get('meta')} />

    return (
        <div className="content-wrapper market-detail-page">
            {meta}

            {hero}

            <div className="page-content container">

              <div className="page-section padded">

                <PropertyPicker marketId={marketId} properties={this.state.properties} outlet={this.props.outlet} />
              </div>



              {positionGrid}

              <div className="page-section padded">
                  <ContentGrid small={12} medium={4}>{marketCards}</ContentGrid>
              </div>


            </div>

        </div>

    );
  }
}