import React, {Component} from 'react';
import Text from '../../../atoms/Text/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';

import './MiniMenu.css';

export default class MiniMenu extends Component  {

    render = () => {
        let colSizeClass = null;
        let links = [];
        let text = null;

        if(this.props.text || this.props.headline) {
            colSizeClass = 'col-lg-6';

            let headline = null;

            if(this.props.headline) {
                headline = (
                    <h3>{this.props.headline}</h3>
                );
            }

            let body = null;
            
            if(this.props.text) {
                body = <Text text={this.props.text} />;
            }

            text = (
                <div className={`text-container ${colSizeClass}`}>
                    <div className="content">
                        <div className="active-indicator"></div>
                        {headline}
                        {body}
                    </div>
                </div>
            );
        }

        for(const link of this.props.links) {
            const renderedLink = (
                <div className="link">
                    <a href={this.getLinkUrl(link.uri)}>{link.title}</a>
                </div>
            );

            links.push(renderedLink);
        }

        let title = null;
        if(this.props.title) {
            title = (
                <div className="title">
                    <h4>{this.props.title}</h4>
                    <hr />
                </div>
            );
        }

        return (
            <div className="mini-menu paragraph page-section padded">
                <div className="row">
                    {text}

                    <div className={`col-12 links ${colSizeClass}`}>
                        <div className="content">
                            <div className="arrow">
                                <FontAwesomeIcon className="bounce" icon={faArrowDown} />
                            </div>
                            
                            {title}
                            {links}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    getLinkUrl = (uri) => {
        const url = uri.replace("internal:", "").replace("entity:", "");

        return url;
    }

}