import React, { Component } from 'react';
import MarketOverviewContent from '../../components/content/market/MarketOverviewContent/MarketOverviewContent';
import FrontPageContent from '../../components/content/front/FrontPageContent/FrontPageContent';
import ArticlesList from '../../components/content/article/ArticlesList/ArticlesList';
import VirtualTourList from '../../components/content/property/VirtualTourList/VirtualTourList';
import CustomersPageContent from '../../components/content/customer/CustomersPageContent/CustomersPageContent';

export default class SpecialPageContent extends Component {

    render() {
        let content = null;

        switch(this.props.page) {
            case 'MARKETS':
                content = <MarketOverviewContent api={this.props.api} />;
                break;
            case 'FRONT':
                content = <FrontPageContent api={this.props.api} />;
                break;
            case 'NEWS':
                content = <ArticlesList api={this.props.api} limit={10} />
                break;
            case 'VIRTUAL_TOUR':
                content = <VirtualTourList api={this.props.api} />
                break;
            case 'CUSTOMERS':
                content = <CustomersPageContent api={this.props.api} />
                break;
            default:
                content = null;
        }
    

        return content;
    }

}