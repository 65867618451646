import React, { Component } from 'react';
import GoogleMap from '../../../../ui/Map/GoogleMap/GoogleMap';
import PropertyListItem from '../../PropertyListItem/PropertyListItem';

export default class PropertyMapWidget extends Component {

    state = {
        items: []
    };

    componentDidMount = () => {
        let items = [];

        if(this.props.properties.hasOwnProperty('data')) {
            for(const item of this.props.properties.data) {
                const building = item.getFirst('field_buildings');
                items.push({
                    name: building.get('field_name'),
                    lat: building.get('field_geolocation').lat,
                    lng: building.get('field_geolocation').lng,
                    type: 'building',
                    id: building.id,
                    hideLabel: true,
                    popupContent: <PropertyListItem key={item.get('id')} entity={item} linkToProperty={true} />
                });
        
            }

            this.setState({
                items: items
            });
        }
    }
    
    render() {

        return (
            <div className="property-map-widget">
                <GoogleMap places={this.state.items} defaultZoom={10} outlet={this.props.outlet} />
            </div>
        );

    }



}