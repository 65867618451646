import React, { Component } from 'react';
import PositionCard from '../PositionCard/PositionCard';
import ContentGrid from '../../../layout/ContentGrid/ContentGrid';

import './PositionGrid.css';

export default class PositionGrid extends Component {
    
    render() {

        const positionCards = this.buildPositionCards();
        let label = null;

        if(this.props.label) {
            label = (
                <h3>{this.props.label}</h3>
            );
        }

        return (
            <div className="position-grid">

                {label}

                <ContentGrid centerIncompleteRow={true} small={12} medium={6} large={3}>{positionCards}</ContentGrid>

            </div>
        );

    }

    buildPositionCards = () => {
        let positions = [];
        let i = 0;

        for(let position of this.props.positions) {
            const person = position.get('field_person');
            if(this.props.featured && i > 0) {
                positions.push(<PositionCard position={position} />);
            } else if(this.props.featured == false) {
                positions.push(<PositionCard position={position} />);
            }

            i++;
        }

        return positions;
    }

}