import React, { Component } from 'react';

import './Body.css';

export default class Body extends Component {

    render() {
        if(this.props.placeholder) {
            return (
                <div className="body placeholder">
                    <div className="body-line"></div>
                    <div className="body-line"></div>
                    <div className="body-line"></div>
                    <div className="body-line"></div>
                    <div className="body-line"></div>
                </div>
            );
        } else {
            const body = {
                __html: this.props.text
            };
    
            return (
                <p dangerouslySetInnerHTML={body} />
            );
        }

    }

}
