import React, { Component } from 'react';
import { Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { withRouter } from 'react-router-dom';

import './SearchBox.css';

class SearchBox extends Component {

    render() {
        let themeClass = null;
        if(this.props.theme) {
            themeClass = `theme-${this.props.theme}`;
        }

        const query = new URLSearchParams(window.location.search);

        let placeholder = 'Search';
        
        if(query.get('keywords')) {
            placeholder = query.get('keywords');
        }

        return (
            <div className={`search-box ${themeClass}`}>
                <Form onSubmit={this.handleSubmit}>
                    <Form.Control name="keywords" type="text" placeholder={placeholder} />
                    <Button type="submit">
                        <FontAwesomeIcon icon={faSearch} />
                    </Button>
                </Form>
            </div>
        );
    }

    handleSubmit = (e) => {
        e.preventDefault();

        this.props.history.push(`/search?keywords=${e.target.children.keywords.value}`);
    }

}

export default withRouter(SearchBox);