import React, { Component } from 'react';

export default class Svg extends Component {

    render() {

        return (
            <div className="svg">
                {this.props.content}
            </div>
        );

    }

}