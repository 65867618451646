import React, { Component } from 'react';
import MarketSliderItem from '../MarketSliderItem/MarketSliderItem';
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import './MarketSlider.css';

export default class MarketSlider extends Component {

    state = {
        markets: null
    };

    componentDidMount = async () => {
        const params = {
            'sort[type][path]': 'field_market_type.weight',
            'sort[title][path]': 'title',
        };

        const markets = await this.props.api.getEntities('node', 'market', params);

        this.setState({
            markets: markets.data
        });
    }

    render() {
        if(this.state.markets) {
            let items = [];

            const sliderSettings = {
                dots: false,
                infinite: true,
                slidesToShow: 4,
                slidesToScroll: 1,
                arrows: false
            };

            for(const item of this.state.markets.data) {
                const title = item.get('title');
                const imageUrl = item.get('field_teaser_image').get('field_media_image').get('links').large_portrait.href;
                const imageAlt = item.get('field_teaser_image').get('field_media_image').alt;
                const linkUrl = item.get('url');

                items.push(<MarketSliderItem title={title} imageUrl={imageUrl} imageAlt={imageAlt} linkUrl={linkUrl} />);
            }

            return (
                <div className="market-slider page-section padded">
                    <div className="row content">
                        <div className="arrows-container col-md-2">
                            <div className="arrows">
                                <div onClick={this.next} className="arrow arrow-next">
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </div>

                                <div onClick={this.prev} className="arrow arrow-prev">
                                    <FontAwesomeIcon icon={faChevronLeft} />
                                </div>
                            </div>

                        </div>

                        <div className="slider col-md-10">
                            <Slider {...sliderSettings} ref={(c) => { this.slider = c }}>{items}</Slider>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <></>
            );
        }
    }

    next = () => {
        this.slider.slickNext();
    }

    prev = () => {
        this.slider.slickPrev();
    }

}