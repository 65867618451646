import React, { Component } from 'react';
import { Image } from 'react-bootstrap';

import './Spinner.css';

export default class Spinner extends Component {

    render() {
        return (
            <div className="spinner page-section padded">
                <Image src="/images/logo-icon-dark.svg" />
            </div>
        );
    }

}