import React, { Component } from 'react';
import Paragraph from '../../components/content/paragraphs/Paragraph/Paragraph';
import Meta from '../../components/atoms/Meta/Meta';
import RecentArticlesList from '../../components/content/article/RecentArticlesList/RecentArticlesList';

import './ArticleDetailPage.css';

export default class ArticleDetailPage extends Component {

    render = () => {
        let content = [];

        for(const paragraph of this.props.entity.get('field_content')) {
            if(!paragraph.get('behavior_settings')?.layout_paragraphs?.parent_uuid) {
                content.push(<Paragraph content={paragraph} drupal={this.props.drupal} />);
            }
        }

        const meta = <Meta data={this.props.entity.get('meta')} />

        return (
            <div className="content-wrapper article-detail-page container page-content">
                {meta}

                <div className="row">
                    <div className="col-12 col-lg-7">
                        <h1 className="title">{this.props.entity.get('title')}</h1>

                        <div className="date">{this.formatDate(this.props.entity.get('field_date'))}</div>

                        <div className="body">{content}</div>
                    </div>

                    <div className="col-12 col-lg-5">
                        <RecentArticlesList limit={4} fancyTitle={false} />
                    </div>
                </div>
            </div>
        );

    }

    formatDate = (dateStr) => {
        const dateParts = dateStr.split('-');
        return `${dateParts[1]}.${dateParts[2]}.${dateParts[0]}`;
    }
}