import React, { Component } from 'react';
import ImageTextItem from '../ImageTextItem/ImageTextItem';
import { Image } from 'react-bootstrap';
import Text from '../../../atoms/Text/Text';

import './ImageTextCollection.css';

export default class ImageTextCollection extends Component {

  render() {
    let title = null;
    if(this.props.title) {
        title = (
            <h2>{this.props.title}</h2>
        );
    }

    let subtitle = null;
    if(this.props.subtitle) {
        subtitle = (
            <h4>{this.props.subtitle}</h4>
        );
    }

    let items = [];
    let i = 1;
    for(let item of this.props.items) {
        const styles = {
            'backgroundImage': `url(${item.get('field_image').get('field_media_image').get('links').extra_large.href})`
        };

        const image = <div className="image" style={styles}></div>;
        const text = <Text text={item.get('field_text').get('field_body').processed} />;

        let firstColContent = text;
        let secondColContent = image;

        if(i % 2 === 0) {
            firstColContent = image;
            secondColContent = text;
        }

        items.push(<ImageTextItem key={item.id} firstColContent={firstColContent} secondColContent={secondColContent} />);

        i++;
    }

    return (
        <div className="paragraph image-text-collection page-section padded">
            {title}
            {subtitle}
            <div className="image-text-items">
                {items}
            </div>
        </div>
    );
  }

}