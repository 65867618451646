import React, { Component } from 'react';
import MenuBranch from '../MenuBranch/MenuBranch';

export default class Submenu extends Component {

    render() {
        // An array to hold our rendered links
        let branches = [];
        // Get the correct menu link using the callback
        const items = this.props.menu(this.props.menuName);

        // Get active link
        const url = this.props.location.pathname;
        const linkData = this.findLinkDataByUrl(items, url)[0];

        if(linkData && linkData.isActiveLink &&linkData.children.length > 0) {

            // Set our maxDepth. 0 is infinite depth. Default is 0.
            let maxDepth = 0;
            if(this.props.depth) {
                maxDepth = this.props.depth;
            }

            const options = {
                maxDepth: maxDepth,
            };

            // For each top level link, render it and its children.
            for(let i = 0; i < linkData.children.length; i++) {
                const currentDepth = 1;

                const branch = (
                    <MenuBranch branchId={linkData.children[i].id} {...options} link={linkData.children[i]} depth={currentDepth} />
                );

                branches.push(branch);
            }

            return (
                <div className="submenu">
                    { branches }
                </div>
            );
        }

        return <></>;
    }

    findLinkDataByUrl = (items, url) => {
        // Return the link object from the jsonapi response.
        return items.filter((element) => element.url === url);
    }

    getLinkUrl = (linkData) => {
        // Convert Drupal uri's to urls for link href's.
        const url = linkData.attributes.link.uri.replace("internal:", "").replace("entity:", "");

        return url;
    }

}